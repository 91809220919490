import { collection, doc, getDoc, updateDoc, serverTimestamp, query, where, getDocs } from 'firebase/firestore';
import { clearAlerts, g, showIntro, showTab, db, moveTopNav, showSignin } from "./globals.js";
import * as utils from './utils.js';

export function homePage() {
    moveTopNav('.intro');
    showIntro();

    // Scroll to top when tabs change
    $('#tabSelector button').on('shown.bs.tab', function (event) {
        $('.introContent').scrollTop(0);
    });

    // Remove hash as we now know where we need to go
    const currentHash = window.location.hash;
    
    if (!g.user) {
        showTab('#searchTab');
        if (currentHash === '#prefs') {
            sessionStorage.setItem('return_to_url', window.location.href);
            showSignin({ contentClass: 'sign-in-manage-prefs-content' });
        }
    } else {
        // Remove hash if there is one
        if (currentHash) {
            // Using history.pushState() to remove the hash
            if (history.pushState) {
                history.pushState(null, null, window.location.href.split('#')[0]);
            } else {
                // Fallback for older browsers - this will not preserve scroll position
                window.location.hash = '';
            }
        }

        // Clear out and refresh recommendations when user logs in
        const $recos = $('.recommendationContainer');
        $recos.html('');
        getRecommendationsByUserId(g.user.uid).then(recommendations => {
            utils.log("loaded recommendations", recommendations);
            if (recommendations.length > 0) {
                renderRecommendations(recommendations);
                if (currentHash === '#prefs') {
                    showTab('#prefsTab');
                } else {
                    showTab('#searchTab');
                }
            } else {
                renderRecommendations(recommendations);
                if (currentHash === '#prefs') {
                    showTab('#prefsTab');
                } else {
                    showTab('#searchTab');
                }
            }
        });
        getTVRecommendationsByUserId(g.user.uid).then(recommendations => {
            utils.log("loaded tv recommendations", recommendations);
            renderTVRecommendations(recommendations);
        })
    }
    $('#tabSelector .nav-link').on('click', function () {
        clearAlerts();
    });
};

function renderRecommendations(recommendations) {
    const $fy = $('#fy')
    if (recommendations.length > 0) {
        $fy.find('.noRecommendations').addClass('d-none');
    } else {
        $fy.find('.noRecommendations').removeClass('d-none');
    }

    const $template = $('#recommendationTemplate');
    const $recoContainer = $fy.find('.recommendationContainer');
    recommendations.forEach(r => {
        const $el = $template.clone();
        $el.removeClass('d-none');
        $el.removeAttr('id');

        $el.find('.title').html(r.title);
        $el.find('.author').html(r.author);
        $el.find('.reason').html(r.reason);
        $el.find('.learnMore').attr('href', r.link);

        $el.find('.thumbs').attr('data-recommendation-id', r.id);

        $el.find('.thumbsUp').on('click', function () {
            let recommendationId = $(this).data('recommendation-id');
            let $icon = $('i', this);
            if ($icon.hasClass('fa-solid')) {
                utils.log('set rating to 0');
                updateRecommendationRating(recommendationId, 0);
            } else {
                utils.log('set rating to 1');
                updateRecommendationRating(recommendationId, 1);
            }
            $icon.toggleClass('fa-regular fa-solid');
            const $grp = $(this).closest('.btn-group');
            const $tub = $grp.find('i.fa-thumbs-down');
            $tub.removeClass('fa-solid');
            $tub.addClass('fa-regular')
        });

        $el.find('.thumbsDown').on('click', function () {
            let recommendationId = $(this).data('recommendation-id');
            let $icon = $('i', this);
            if ($icon.hasClass('fa-solid')) {
                utils.log('set rating to 0');
                updateRecommendationRating(recommendationId, 0);
            } else {
                utils.log('set rating to -1');
                updateRecommendationRating(recommendationId, -1);
            }
            $icon.toggleClass('fa-regular fa-solid');
            const $grp = $(this).closest('.btn-group');
            const $tub = $grp.find('i.fa-thumbs-up');
            $tub.removeClass('fa-solid');
            $tub.addClass('fa-regular');
        });

        $recoContainer.append($el);
    })
}

async function getRecommendationsByUserId(userId) {
    // Reference to the collection
    const recommendationsRef = collection(db, 'recommendations');

    // Create a query against the collection
    const q = query(recommendationsRef,
        where('userId', '==', userId),
        where('ratedAt', '==', null)
    );

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Map results to an array of data
    const recommendations = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));

    // Shuffle the array using sort() and Math.random()
    const shuffled = recommendations.sort(() => 0.5 - Math.random());

    // Select the first 6 items from the shuffled array
    const finalRecommendations = shuffled.slice(0, 6);

    return finalRecommendations;
}

function renderTVRecommendations(recommendations) {
    const $fytv = $('#fytv');

    if (recommendations.length > 0) {
        $fytv.find('.noRecommendations').addClass('d-none');
    } else {
        utils.log('here');
        $fytv.find('.noRecommendations').removeClass('d-none');
    }

    const $template = $('#tvRecommendationTemplate');
    const $recoContainer = $fytv.find('.recommendationContainer');
    recommendations.forEach(r => {
        const $el = $template.clone();
        $el.removeClass('d-none');
        $el.removeAttr('id');

        $el.find('.title').html(r.title);
        $el.find('.year').html(r.year);
        $el.find('.countryCode').html(r.countryCode);
        $el.find('.reason').html(r.reason);
        $el.find('.learnMore').attr('href', r.link);

        $el.find('.thumbs').attr('data-recommendation-id', r.id);

        $el.find('.thumbsUp').on('click', function () {
            let recommendationId = $(this).data('recommendation-id');
            let $icon = $('i', this);
            if ($icon.hasClass('fa-solid')) {
                utils.log('set rating to 0');
                updateTVRecommendationRating(recommendationId, 0);
            } else {
                utils.log('set rating to 1');
                updateTVRecommendationRating(recommendationId, 1);
            }
            $icon.toggleClass('fa-regular fa-solid');
            const $grp = $(this).closest('.btn-group');
            const $tub = $grp.find('i.fa-thumbs-down');
            $tub.removeClass('fa-solid');
            $tub.addClass('fa-regular')
        });

        $el.find('.thumbsDown').on('click', function () {
            let recommendationId = $(this).data('recommendation-id');
            let $icon = $('i', this);
            if ($icon.hasClass('fa-solid')) {
                utils.log('set rating to 0');
                updateTVRecommendationRating(recommendationId, 0);
            } else {
                utils.log('set rating to -1');
                updateTVRecommendationRating(recommendationId, -1);
            }
            $icon.toggleClass('fa-regular fa-solid');
            const $grp = $(this).closest('.btn-group');
            const $tub = $grp.find('i.fa-thumbs-up');
            $tub.removeClass('fa-solid');
            $tub.addClass('fa-regular');
        });

        $recoContainer.append($el);
    })
}


async function getTVRecommendationsByUserId(userId) {
    // Reference to the collection
    const recommendationsRef = collection(db, 'tv_recommendations');

    // Create a query against the collection
    const q = query(recommendationsRef,
        where('userId', '==', userId),
        where('ratedAt', '==', null)
    );

    // Execute the query
    const querySnapshot = await getDocs(q);

    // Map results to an array of data
    const recommendations = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }));

    // Shuffle the array using sort() and Math.random()
    const shuffled = recommendations.sort(() => 0.5 - Math.random());

    // Select the first 6 items from the shuffled array
    const finalRecommendations = shuffled.slice(0, 6);

    return finalRecommendations;
}

const updateRecommendationRating = async (recommendationId, rating) => {
    const user = g.user;
    if (user) {
        // User is signed in, update the recommendation rating
        const recommendationRef = doc(db, 'recommendations', recommendationId);
        const docSnap = await getDoc(recommendationRef);

        if (docSnap.exists()) {
            // Check current rating and toggle
            const currentRating = docSnap.data().rating || 0;
            const newRating = (currentRating === rating) ? 0 : rating;
            await updateDoc(recommendationRef, {
                rating: newRating,
                ratedAt: serverTimestamp() // Set the ratedAt timestamp
            });
        } else {
            utils.log("No such document!");
        }
    } else {
        // No user is signed in
        utils.log('User is not signed in.');
        // Optionally, prompt the user to sign in
    }
};

const updateTVRecommendationRating = async (recommendationId, rating) => {
    const user = g.user;
    if (user) {
        // User is signed in, update the recommendation rating
        const recommendationRef = doc(db, 'tv_recommendations', recommendationId);
        const docSnap = await getDoc(recommendationRef);

        if (docSnap.exists()) {
            // Check current rating and toggle
            const currentRating = docSnap.data().rating || 0;
            const newRating = (currentRating === rating) ? 0 : rating;
            await updateDoc(recommendationRef, {
                rating: newRating,
                ratedAt: serverTimestamp() // Set the ratedAt timestamp
            });
        } else {
            utils.log("No such document!");
        }
    } else {
        // No user is signed in
        utils.log('User is not signed in.');
        // Optionally, prompt the user to sign in
    }
};