import { router } from "./router.js";
import { showIntro, showSignin, showTab, moveTopNav } from "./globals.js";
import { g } from './globals.js';

export function signinPage() {
    moveTopNav('.intro');
    showIntro();
    showTab('#searchTab');
    if (g.user) {
        history.pushState(null, '', '/');
        router();
    } else {
        showSignin();
    }
};