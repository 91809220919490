import { homePage } from './home.js';
import { recommendationsPage } from './recommendations.js';
import { emailSettingsPage } from './email_settings.js';
import { signinPage } from './account.js';

// Define your routes
const routes = {
  '/': homePage,
  '/signin': signinPage,
  '/recommendations': recommendationsPage,
  '/settings/emails': emailSettingsPage,
};

function router() {
  let path = window.location.pathname;
  $('.intro').addClass('d-none');
  $('#emailSettingsPage').addClass('d-none');
  $('.chat').addClass('d-none');
  if (routes[path]) {
    routes[path]();
  } else {
    // Handle 404
  }
}

window.addEventListener('popstate', router);

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('click', e => {
    if (e.target.matches('[data-link]')) {
      e.preventDefault();
      history.pushState(null, '', e.target.href);
      router();
    }
  });
});

// Export the router function for use in other files
export { router };