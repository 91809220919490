import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, EmailAuthProvider, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, collection } from 'firebase/firestore';

import * as affiliates from './affiliates.js';
import * as utils from './utils.js';

const PRODUCT_STR = "romance book";
const PRODUCTS_STR = "romance books";

const firebaseConfig = require('../firebaseConfig');
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const g = {
    user: null,
    userData: null,
    history: [],
    stopResponse: false,
    affiliateLinks: affiliates.getLinks(),
    usersCollection: collection(db, "users"),
};

const uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
    ],
    privacyPolicyUrl: "https://www.speran.com/privacy-policy",
    tosUrl: "https://www.speran.com/terms-of-service",
    callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            return false;
        }
    }
};

const showSignup = function (opts = {}) {
    if (opts.reachedMaxLimit == true) {
        $("#signup .message").removeClass("d-none");
    } else {
        $("#signup .message").addClass("d-none");
    }
    showSignin({ contentClass: 'sign-up-content' });
};

const showSignupAfterLimitHit = function (opts = {}) {
    $("#signup .message").removeClass("d-none");
    showSignin({ contentClass: 'sign-up-after-limit-hit-content' });
};

const showSignin = function (opts = {}) {
    $("#signup .message").addClass("d-none");
    $('#signup .content').addClass('d-none');
    
    if (opts.contentClass) {
        $(`#signup .${opts.contentClass}`).removeClass('d-none');
    } else {
        $('#signup .sign-in-content').removeClass("d-none");
    }
    $("#signup").modal("show");
};

const moveTopNav = function (targetId) {
    const $tn = $('#topNav');
    const $introNavPlaceholder = $(targetId).find('.topNavPlaceholder');
    if ($introNavPlaceholder.length > 0) {
        $tn.appendTo($introNavPlaceholder);
    };
};

const showIntro = function () {
    g.stopResponse = true;
    let $app = $("#app");
    let $intro = $app.find(".intro");
    let $introContent = $(".introContent");
    let $chat = $app.find(".chat");
    let $pr = $app.find(".promptResponse");
    let $so = $('.startOver');

    hideNavBar();
    $chat.addClass("d-none");
    $pr.html("");
    $intro.removeClass("d-none");
    $so.addClass("d-none");

    $introContent.scrollTop(0);

    $('#presetPrefs select').prop("selectedIndex", 0);
    $('#savedPrefs select').prop("selectedIndex", 0);

    calculateIntroHeight();
};

function hideNavBar() {
    let offcanvasElement = document.getElementById('offcanvasNavbar');
    if (offcanvasElement) {
        let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
        // Hide the offcanvas menu
        if (offcanvasInstance) { offcanvasInstance.hide(); }
    }
}

// Allows intro to scroll properly
const calculateIntroHeight = function () {
    let $intro = $(".intro");
    let viewportHeight = window.innerHeight - $intro.offset().top;
    // Set viewport height based on where the app is located on the screen
    $intro.css("height", `${viewportHeight}px`);
};
window.addEventListener('resize', calculateIntroHeight);

const showTab = function (id) {
    var triggerEl = document.querySelector(id);
    var tab = new bootstrap.Tab(triggerEl);
    tab.show(); // This activates the tab and shows the associated tab pane
};

const clearAlerts = function() {
    utils.log('clear alerts');
    $('#alertsContainer').html('');
}

const showAlert = function (message, type = 'alert-warning') {
    const alertsContainer = document.getElementById('alertsContainer');
    const alertElement = document.createElement('div');
    alertElement.className = `alert ${type} alert-dismissible fade show`;
    alertElement.role = 'alert';
    alertElement.innerHTML = `
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        `;

    $(alertsContainer).html("");
    alertsContainer.appendChild(alertElement);
    $('.introContent').scrollTop(0);
}

export { g, app, db, uiConfig, PRODUCT_STR, PRODUCTS_STR, showSignup, showSignupAfterLimitHit, showSignin, showIntro, hideNavBar, showTab, showAlert, clearAlerts, moveTopNav };
