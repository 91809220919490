import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { g, showAlert, moveTopNav, showSignin } from "./globals.js";
import * as utils from './utils.js';

const updateEmailSettings = function (userId, data) {
    const docRef = doc(g.usersCollection, userId);
    updateDoc(docRef, data);
}

export function emailSettingsPage() {
    utils.log('email settings page');

    moveTopNav('#emailSettingsPage');

    // Use window.location.search to get the query string part of the current URL
    const queryString = window.location.search;

    // Create an instance of URLSearchParams to parse the query string
    const urlParams = new URLSearchParams(queryString);

    // Use the get() method of URLSearchParams to get the value of 'param2'
    let userId = urlParams.get('user_id');

    if (!userId && g.user) {
        userId = g.user.uid;
    }

    if (userId) {
        const docRef = doc(g.usersCollection, userId);
        getDoc(docRef).then(docSnap => {
            if (docSnap.exists()) {
                const user = docSnap.data();
                utils.log(user);
                $('#emailSettingsPage').removeClass('d-none');
                if (user.weeklyEmailSubscription != false) {
                    $('#weeklyEmailSubscription').prop('checked', true);
                }

                $('.saveEmailSettings').on('click', function () {
                    const checked = $('#weeklyEmailSubscription').prop('checked');
                    updateEmailSettings(userId, {
                        weeklyEmailSubscription: checked
                    });
                    showAlert("Your email settings have been updated.", 'alert-secondary');
                })
            }
        });
    } else {
        sessionStorage.setItem('return_to_url', window.location.href);
        showSignin();
    }
};