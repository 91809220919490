import { getAuth, GoogleAuthProvider, onAuthStateChanged, deleteUser, signInWithPopup, signOut } from 'firebase/auth';
import { collection, doc, getDoc, updateDoc, setDoc, deleteDoc, serverTimestamp, query, where, getDocs, Timestamp } from 'firebase/firestore';

import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

import * as utils from './utils.js';
import { g, app, db, uiConfig, PRODUCT_STR, PRODUCTS_STR, showSignin, showSignup, showSignupAfterLimitHit, showIntro, hideNavBar, showTab, showAlert, clearAlerts, moveTopNav } from './globals.js';
import { router } from './router.js';

// Get a user id for unauthenticated users
setUserId();

$(document).ready(function () {

    const auth = getAuth(app);
    g.user = auth.currentUser;

    // Initialize the FirebaseUI Widget using Firebase.
    const ui = new firebaseui.auth.AuthUI(auth);
    g.ui = ui;

    configureAuthStateChanged(auth);

    ui.start('#firebaseui-auth-container', uiConfig);

    trackLogins();

    let $app = $("#app");

    let $prompt = $app.find(".prompt");
    let $promptInput = $prompt.find("textarea");
    let $bs = $prompt.find("button.sendMessage");

    let $so = $('.startOver');
    $so.on("click", function () {
        showIntro();
        moveTopNav('.intro');
    })

    let $qs = $app.find(".quickStart");
    $qs.on("click", function () {
        clearAlerts();
        $qs.html(`<i class="fa fa-spinner fa-spin fa-xs"></i>`).prop("disabled", true);

        let promptText = `Help me find the perfect ${PRODUCT_STR}.`;
        submitMessage({ hideMessage: true, quickStart: true, promptText: promptText });
    });

    let $sp = $('.savePreferences');
    $sp.on('click', function () {
        if (g.user) {
            let elementsInput = $("#prefsDetails").val().trim();
            let favoritesInput = $("#prefsFavorites").val().trim();
            updateUser(g.user.uid, { romanceBookPrefs: elementsInput, romanceBookFaves: favoritesInput })
            showAlert("Your preferences have been saved.", "alert-secondary");
        } else {
            showAlert("Sorry, there was an error. Please try again later.", "alert-warning");
        }
    });

    let $as = $app.find(".advancedStart");
    $as.on("click", function () {
        clearAlerts();
        $as.html(`<i class="fa fa-spinner fa-spin fa-xs"></i>`).prop("disabled", true);
        let elements = "";
        let favorites = "";

        let elementsInput = $("#elements").val().trim();
        let favoritesInput = $("#favorites").val().trim();

        if (elementsInput.length > 0) {
            elements = `The elements I care most about in a ${PRODUCT_STR} are: ${elementsInput}.`
        }

        if (favoritesInput.length > 0) {
            favorites = `My favorite ${PRODUCTS_STR} are: ${favoritesInput}.`
        }

        let promptText = `Help me find the perfect ${PRODUCT_STR}.${elements}${favorites}`;

        const includeSavedPrefs = $('#includeSavedPrefs').prop('checked');
        if (g.userData && includeSavedPrefs) {
            // Include the saved preferences in all ChatGPT sessions
            let savedPrefs = g.userData.romanceBookPrefs;
            let savedFaves = g.userData.romanceBookFaves;
            if (savedPrefs && savedPrefs.length > 0) {
                promptText += ` I also have the following preferences in a romance book: ${savedPrefs}.`
            }
            if (savedFaves && savedFaves.length > 0) {
                promptText += ` These are also my favorite romance books: ${savedFaves}.`
            }
        }

        let quickStart = false;
        if (elementsInput.length === 0 && favoritesInput.length === 0 && !includeSavedPrefs) {
            quickStart = true;
        }

        // Trigger the initial prompt
        submitMessage({ hideMessage: true, quickStart: quickStart, promptText: promptText });
    });

    $promptInput.keydown(function (event) {
        if (event.key == "Enter") {
            event.preventDefault();
            submitMessage();
        }
    });

    $bs.on("click", function (event, arg1) {
        submitMessage(arg1);
    });

    $("textarea").on("input", function () {
        autoExpand(this);
    });

    $('#presetPrefs select').change(function () {
        var selectedValue = $(this).val();
        $("#elements").val(selectedValue);
        gtag('event', 'prefs_preset_select_changed', {
            'event_category': 'preferences',
            'event_label': 'Preset Preferences Changed'
        });
    });

    $(".nav-sign-in").on("click", function () {
        localStorage.setItem('preSignInAction', "login");
        showSignin();
    });

    $(".nav-sign-up").on("click", function () {
        localStorage.setItem('preSignInAction', "signup");
        showSignup();
    });


    $(".nav-sign-out").on("click", function () {
        utils.log("signing out");
        signOut(auth);
        g.user = null;
        g.userData = null;
        localStorage.setItem('preSignOutAction', 'logout');
    });

    $(".nav-delete-account").on("click", function () {
        utils.log("deleting account");
        deleteAccount();
    });

    $('.showSavedPreferences').on('click', function (e) {
        e.stopPropagation();
        showTab('#prefsTab');
    });

    $('.cbx-container').on('click', function (e) {
        // Check if the clicked element is not the checkbox
        let $target = $(e.target);

        // Toggle the checkbox
        let $parent = $target.closest(".cbx-container");
        let $cbx = $parent.find('input[type="checkbox"]');

        let checked = $cbx.prop('checked');

        if (!$target.is('input[type="checkbox"]') && !$target.is('label')) {
            $cbx.prop('checked', !checked);
        }
    });

    document.body.addEventListener('click', e => {
        switch (e.target.nodeName) {
            case "A":
                if (e.target.matches('[data-link]')) {
                    e.preventDefault();
                    clearAlerts();
                    history.pushState(null, '', e.target.href);
                    router();
                }
                break;
            default:
                if (e.target.matches('[data-link]')) {
                    e.preventDefault();
                    clearAlerts();
                    history.pushState(null, '', e.target.getAttribute('data-link'));
                    router();
                }
        }
    });
});

window.addEventListener('popstate', router);

window.onerror = function (message, source, lineno, colno, error) {
    // Construct an error message
    const errorMessage = `Error message: ${message}\n` +
        `Source: ${source}\n` +
        `Line: ${lineno}\n` +
        `Column: ${colno}\n` +
        `Error object: ${JSON.stringify(error)}`;

    // Send this error message to your server
    sendErrorReport(errorMessage);

    // Return true to prevent the default browser error handler
    return true;
};

function sendErrorReport(errorMessage) {
    utils.log(errorMessage);
    fetch('/api/errorReports', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ error: errorMessage }),
    })
        .then(response => {
            if (!response.ok) {
                // Handle server errors or network issues
                console.error('Failed to send error report to the server');
            }
        })
        .catch(error => {
            // Handle inability to reach your server
            console.error('Error sending error report:', error);
        });
}

const trackLogins = function () {
    setTimeout(function () {
        // Target the Google Sign-In button. The selector might change based on FirebaseUI version
        const googleButton = document.querySelector('.firebaseui-idp-google');

        if (googleButton) {
            googleButton.addEventListener('click', function () {
                // Send an event to GA4 when the Google sign-in button is clicked
                gtag('event', 'sign_in_with_google_clicked', {
                    'event_category': 'Authentication',
                    'event_label': 'Sign In With Google'
                });
            });
        }

        const emailButton = document.querySelector('.firebaseui-idp-password');

        if (emailButton) {
            emailButton.addEventListener('click', function () {
                // Send an event to GA4 when the Google sign-in button is clicked
                gtag('event', 'sign_in_with_email_clicked', {
                    'event_category': 'Authentication',
                    'event_label': 'Sign In With Email'
                });
            });
        }
    }, 1000); // Adjust the timeout as necessary
};

async function sendMessage(opts = {}) {

    let messages = g.history;

    let prompt = opts.prompt;

    messages.push({ "role": "user", "content": prompt });

    const requestBody = {
        messages: messages,
        productStr: PRODUCT_STR,
        productsStr: PRODUCTS_STR,
        userId: getUserId(),
        quickStart: opts.quickStart
    };

    try {
        const user = g.user;
        let idToken = null;

        if (user) {
            idToken = await user.getIdToken();
        }

        const headers = {
            'Content-Type': 'application/json',
        };

        if (idToken) {
            headers['Authorization'] = 'Bearer ' + idToken;
        }

        const response = await fetch('/media/sendMessage', {
            method: 'POST',
            credentials: 'include',
            headers: headers,
            body: JSON.stringify(requestBody),
        });

        if (response.ok) {
            let current = opts.onStart();

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            let responseText = "";

            while (true) {
                const { done, value } = await reader.read();
                if (done || g.stopResponse) break;

                // Decode the chunk
                const chunk = decoder.decode(value, { stream: true });

                responseText += chunk;

                opts.onDataReceived({ data: responseText, current: current });
            }
            messages.push({ "role": "assistant", "content": responseText });
            opts.onEnd();
        } else if (response.status === 403) {
            opts.onUnauthorized();
        } else if (response.status === 400) {
            opts.onError();
        }
    } catch (err) {
        utils.log("error", err);
        opts.onError();
    }
}

const resetPrompt = function () {
    let $app = $("#app");
    let $prompt = $app.find(".prompt");
    let $bs = $prompt.find("button.sendMessage");

    $bs.html(`<i class="fa-solid fa-paper-plane"></i>`).prop("disabled", false);
};

const submitMessage = function (opts = {}) {
    let $app = $("#app");
    let $prompt = $app.find(".prompt");
    let $promptInput = $prompt.find("textarea");
    let $bs = $prompt.find("button.sendMessage");
    let $pr = $app.find(".promptResponse");

    let message = opts.promptText;

    if (typeof (message) === "undefined") {
        message = $prompt.find("textarea").val().trim();
    }

    // Don't send a request if there's no message
    if (message.length === 0) { return; }

    // Start spinner
    $bs.html(`<i class="fa fa-spinner fa-spin fa-xs"></i>`).prop("disabled", true);
    sendMessage({
        prompt: message,
        quickStart: opts.quickStart,
        onStart: function (args) {
            
            moveTopNav('.chat');
            showChat();

            // User has sent a message so make sure we can get a response
            g.stopResponse = false;

            // Reset prompt
            $promptInput.val("");

            // Reset height of textarea
            $promptInput.removeClass("expanded");

            // Show prompt response
            $pr.removeClass("d-none");

            // Scroll the user to the bottom of the response
            $pr.scrollTop($pr.prop('scrollHeight'));

            let html = $pr.html();

            if (opts.hideMessage !== true) {
                let yourMessage = message.replace(/\n/g, "<br>");
                html += `
                        <div class="py-2"><strong><i class="fa-solid fa-circle-user me-2"></i>You</strong></div>
                        <div class="mb-4">${yourMessage}</div>
                    `;
            }

            html += `
                    <div class="py-2"><strong><i class="fa-brands fa-gg-circle me-2"></i>ChatGPT</strong></div>
                `;

            $pr.html(html);

            return $pr.html();
        },
        onDataReceived: function (args) {
            let current = args.current;
            let html = current + args.data;
            html = replaceLinksWithAffiliateLinks(html, g.affiliateLinks);

            html = html + `<i class="fa-solid fa-spinner fa-spin fa-sm indicator ms-1"></i>`;

            // Display the response
            $pr.html(html);

            // Scroll the user to the bottom of the response as more content gets added
            $pr.scrollTop($pr.prop('scrollHeight'));
        },
        onEnd: function () {
            let endText = `
                        <div class="mb-4"></div>
                        <hr />
                    `;
            let html = $pr.html() + endText;
            $pr.html(html);

            $pr.find(".indicator").remove();

            // Scroll the user to the bottom of the response
            $pr.scrollTop($pr.prop('scrollHeight'));

            // Reset button
            resetPrompt();
        },
        onError: function (args) {
            let endText = `
                        <div class="mb-4">Sorry, there was an error. Please try again later.</div>
                        <hr />
                    `;
            $pr.html($pr.html() + endText);

            resetPrompt();

            // Needed if the user is coming from intro page
            resetStarts();
        },
        onUnauthorized: function () {
            localStorage.setItem('preSignInAction', "submitMessage");
            gtag('event', 'limit_hit', {
                'event_category': 'recommendations',
                'event_label': 'Recommendation Limit Reached'
            });

            showSignupAfterLimitHit();

            resetPrompt();

            // Needed if the user is coming from intro page
            resetStarts();
        }
    });
};

// Allows chat to scroll properly
const calculateChatHeight = function () {
    let $chat = $(".chat");
    let viewportHeight = window.innerHeight - $chat.offset().top;
    // Set viewport height based on where the app is located on the screen
    $chat.css("height", `${viewportHeight}px`);
};
window.addEventListener('resize', calculateChatHeight);

const showChat = function () {
    utils.log("showchat");
    let $app = $("#app");
    let $intro = $app.find(".intro");
    let $chat = $app.find(".chat");
    let $qs = $app.find(".quickStart");
    let $as = $app.find(".advancedStart");
    let $so = $('.startOver');

    hideNavBar();
    $chat.removeClass("d-none");
    $intro.addClass("d-none");
    $so.removeClass("d-none");
    $qs.html(`Skip`).prop("disabled", false);
    $as.html(`Get recommendation`).prop("disabled", false);
    calculateChatHeight();
};

const resetStarts = function () {
    utils.log("resetstarts");
    $('.quickStart').html(`Skip`).prop("disabled", false);
    $('.advancedStart').html(`Get recommendation`).prop("disabled", false);
};

const reauthenticateWithGoogle = function () {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    try {
        signInWithPopup(auth, provider)
            .then((result) => {
                // After successful reauthentication, try to delete the account again
                utils.log("User reauthenticated");
                // The signed-in user info.
                g.user = result.user;
                deleteAccount();
            });
    } catch (error) {
        utils.log("Reauthentication failed", error);
    }
};

const deleteAccount = () => {
    if (g.user) {
        // Confirm with the user before deletion
        if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {

            deleteUser(g.user).then(() => {

                // Also delete user from our DB
                deleteUserData();

                g.user = null;
                g.userData = null;

                dataLayer.push({
                    'event': 'account_deleted',
                    'eventCategory': 'User Engagement',
                    'eventAction': 'Account Deleted'
                });

                // Redirect to home page or show a message
                localStorage.setItem('preSignOutAction', 'deleteAccount');
                handlePostSignOutAction();

            }).catch((error) => {
                // An error happened.
                if (error.code === 'auth/requires-recent-login') {
                    // Prompt the user to re-authenticate
                    reauthenticateWithGoogle();
                } else {
                    utils.log("Error deleting user:", error);
                }

            });
        }
    }
};

const deleteUserData = async () => {
    const docRef = doc(db, "users", g.user.uid);
    try {
        await deleteDoc(docRef);
        utils.log("User set for deletion.");
    } catch (error) {
        utils.log("Error udpating user document:", error);
    }
};

function replaceLinksWithAffiliateLinks(inputString, urlMappings) {
    // Regular expression to match URLs
    const urlRegex = /https:\/\/www\.google\.com\/search\?[^\s]+/i;

    let $html = $("<div>" + inputString + "</div>");
    $html.find('a').each(function () {
        var $link = $(this);
        let url = $link.attr("href");

        // Sometimes getting undefined for some reason
        if (url) {
            url = url.replace(/\+/g, "%20");
            url = url.toLowerCase();
            if (urlMappings.hasOwnProperty(url)) {
                // Replace with the URL from the JSON object
                let affUrl = urlMappings[url].affiliate_url;
                $link.attr("href", affUrl);
                $link.addClass("affiliateLink");
            }
        }
    });
    return $html.prop("innerHTML");
}

function autoExpand(textarea) {
    // Reset the height to ensure the scroll height calculation is correct
    textarea.style.height = 'auto';

    // Set the height to the scroll height, which represents the height of the content
    textarea.style.height = textarea.scrollHeight + 'px';
}

const viewAsLoggedIn = function () {
    // Change navbar action to logout instead of sign in
    $('.nav-sign-out').parent().removeClass("d-none");
    $('.nav-sign-out').prop("disabled", false);
    $('.nav-sign-in').parent().addClass("d-none")
    $('.nav-sign-in').prop("disabled", true);
    $('.nav-sign-up').parent().addClass("d-none")
    if (g.user && g.user.photoURL && g.user.photoURL.length > 0) {
        $('.navbar-toggler').html(`<img src="${g.user.photoURL}" class="rounded-circle profile-pic">`);
    }
    $('.nav-delete-account').parent().removeClass("d-none");
    $('.nav-delete-account').prop("disabled", false);

    $('.logged-in-content').removeClass('d-none');
    $('.logged-out-content').addClass('d-none');
};

const viewAsLoggedOut = function () {
    // Change navbar action to sign in instead of logout
    $('.nav-sign-out').parent().addClass("d-none")
    $('.nav-sign-out').prop("disabled", true);
    $('.nav-sign-in').parent().removeClass("d-none");
    $('.nav-sign-in').prop("disabled", false);
    $('.nav-sign-up').parent().removeClass("d-none")
    $('.navbar-toggler').html(`<i class="fa-regular fa-circle-user fa-xl unregisteredProfilePic"></i>`);
    $('.nav-delete-account').parent().addClass("d-none");
    $('.nav-delete-account').prop("disabled", true);

    $('#elements').val("");
    $('#favorites').val("");

    $('.logged-in-content').addClass('d-none');
    $('.logged-out-content').removeClass('d-none');
};

const configureAuthStateChanged = function (auth) {
    onAuthStateChanged(auth, (user) => {
        utils.log("authstatechanged");
        clearAlerts();
        if (user) {
            utils.log("loggedin");

            // Allows us to acccess the history of the user as they become a registered user
            const originalUserId = getUserId();

            // Use the new user information coming from firebaseauth
            g.user = user;
            setUserId(g.user);

            // Check if new user and set up user in database
            const docRef = doc(g.usersCollection, user.uid);
            getDoc(docRef).then(docSnap => {
                if (!docSnap.exists()) {
                    // Save prefs
                    let prefs = $("#elements").val().trim();
                    let faves = $("#favorites").val().trim();

                    const creationTimestamp = Timestamp.fromDate(new Date(user.metadata.creationTime));

                    const udata = {
                        name: user.displayName,
                        email: user.email,
                        photoURL: user.photoURL,
                        emailVerified: user.emailVerified,
                        providerData: user.providerData,
                        refreshToken: user.refreshToken,
                        phoneNumber: user.phoneNumber,
                        creationTime: creationTimestamp,
                        lastSignInTime: creationTimestamp,
                        tenantId: user.tenantId,
                        romanceBookPrefs: prefs,
                        romanceBookFaves: faves,
                        weeklyEmailSubscription: true,
                    };
                    setDoc(docRef, udata);

                    // Keep the chat history when they become a registered user
                    updateUserIdsForMessages(originalUserId, user.uid);
                    g.userData = udata;

                    // New user
                    dataLayer.push({
                        'event': 'signup_completed',
                        'eventCategory': 'User Engagement',
                        'eventAction': 'Signup Completed'
                    });
                } else {
                    g.userData = docSnap.data();
                    updateUser(user.uid, { lastSignInTime: serverTimestamp() });
                }
                // Update UI to reflect logged in state
                viewAsLoggedIn();

                // Handle the action
                handlePostSignInAction();
            });
        } else {
            // Clear prefs
            utils.log("loggedout");
            $("#elements").val("");
            $("#favorites").val("");
            viewAsLoggedOut();
            handlePostSignOutAction();
        }
        $("#signup").modal("hide");
        $('#loading').addClass('d-none');
        $('#app').removeClass('d-none');
    });
};

function handlePostSignOutAction() {
    // Retrieve the action from storage
    const action = localStorage.getItem('preSignOutAction');

    // Clear the stored action
    localStorage.removeItem('preSignOutAction');

    switch (action) {
        case 'deleteAccount':
            history.pushState(null, '', '/');
            showAlert("Your account has been successfully deleted.", "alert-secondary");
            router();
            break;
        case 'logout':
            history.pushState(null, '', '/');
            showAlert("You have been successfully signed out.", "alert-secondary");
            router();
            break;
        default:
            router();
    }

    g.ui.start('#firebaseui-auth-container', uiConfig);
}

function handlePostSignInAction() {
    // Load user prefs
    $('#prefsDetails').val(g.userData.romanceBookPrefs);
    $('#prefsFavorites').val(g.userData.romanceBookFaves);

    // Retrieve the action from storage
    const action = localStorage.getItem('preSignInAction');

    // Clear the stored action
    localStorage.removeItem('preSignInAction');

    const returnUrl = sessionStorage.getItem('return_to_url');
    if (returnUrl) {
        sessionStorage.removeItem('return_to_url');
        utils.log('returnUrl', returnUrl);
    }

    utils.log('login action', action);
    switch (action) {
        case 'login':
            if (returnUrl) {
                history.pushState(null, '', returnUrl);
            }
            router();
            break;
        case 'signup':
            // Navigate the user to intro
            history.pushState(null, '', '/');
            showAlert("You have successfully signed up - enjoy the unlimited recommendations!", "alert-secondary");
            router();
            break;
        case 'submitMessage':
            history.pushState(null, '', '/');
            // resend message
            submitMessage();
            break;
        default:
            if (returnUrl) {
                history.pushState(null, '', returnUrl);
            }
            router();
    }
}

function updateUser(uid, newData) {
    const docRef = doc(g.usersCollection, uid);

    g.userData = Object.assign({}, g.userData, newData);
    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
            // User exists, update their document
            updateDoc(docRef, newData).then(() => {
                utils.log("User document successfully updated!");
            }).catch((error) => {
                utils.log("Error updating document: ", error);
            });
        } else {
            // User document does not exist, handle accordingly
            utils.log("No such user document!");
        }
    }).catch((error) => {
        utils.log("Error getting user document:", error);
    });
}

function getUserId() {
    return localStorage.getItem('userId');
}

function setUserId(user) {
    let userId = utils.generateUUID();
    if (user) { userId = user.uid; }
    localStorage.setItem('userId', userId);
}

async function updateUserIdsForMessages(oldUserId, newUserId) {
    const messagesRef = collection(db, "messages"); // "messages" is your collection
    const q = query(messagesRef, where("userId", "==", oldUserId));
  
    const querySnapshot = await getDocs(q);
    const updatePromises = [];
  
    querySnapshot.forEach((docSnapshot) => {
      // For each document, create a promise to update it
      const docRef = doc(db, "messages", docSnapshot.id);
      updatePromises.push(updateDoc(docRef, { "userId": newUserId }));
    });
  
    // Wait for all updates to complete
    try {
      await Promise.all(updatePromises);
      console.log("All documents updated successfully.");
    } catch (error) {
      console.error("Error updating documents:", error);
    }
  }